input[type='text'] {
  -webkit-appearance: none;
}

/* For most modern browsers */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input-number:focus {
  border: none;
  outline: none;
}

.up-arrowsize svg {
  width: 7px;
  height: 7px;
}

.up-arrowsize svg path {
  fill: #726f7e;
}

.input-small {
  height: 26px;
}
